<template>
    <div>
    <div class="page-wrapper">
		<div class="header-top">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-6">
        <div class="top-left text-center text-md-left">
          <h6>Horario de atención : Lunes - Viernes - 9am a 6pm</h6>
        </div>
      </div>
      <div class="col-md-6">
        <div class="top-right text-center text-md-right">
          <ul class="social-links">
            <li>
              <a href="https://themefisher.com/" aria-label="facebook">
                <i class="fab fa-facebook-f"></i>
              </a>
            </li>
            
          </ul>
        </div>
      </div>
    </div>
  </div>
    <section class="header-uper bg-white">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-xl-4 col-lg-3">
        <div class="logo">
          <a href="index.html">
            <img loading="lazy" class="img-fluid" src="images/logo.png" alt="logo">
          </a>
        </div>
      </div>
      <div class="col-xl-8 col-lg-9">
        <div class="right-side">
          <ul class="contact-info pl-0 mb-4 mb-md-0">
            <li class="item text-left">
              <div class="icon-box">
                <i class="far fa-envelope"></i>
              </div>
              <strong>Email</strong>
              <br>
              <a href="mailto:info@medic.com">
                <span>contacto@esaky.mx</span>
              </a>
            </li>
            <li class="item text-left">
              <div class="icon-box">
                <i class="fas fa-phone"></i>
              </div>
              <strong>Tel.</strong>
              <br>
              <span>(722) 270-7021</span>
            </li>
          </ul>
          <div class="link-btn text-center text-lg-right">
            <a href="#" target="_blank" class="btn-style-one" data-toggle="modal" data-target="#exampleModal">DEMO ONLINE</a>
          </div>
        </div>
      </div>
    </div>
  </div>
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 style="color: #de061d; text-align: center" class="modal-title" id="exampleModalLabel">AVISO</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="color: #000">
       Ingresa los siguientes datos para iniciar la sesión<br>
       USUARIO: prueba<br>
       CONTRASEÑA: prueba      
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal">Cancelar</button>
        <a href="https://sistema.esaky.mx/#/" target="_blank"><button type="button" class="btn btn-success">Continuar</button></a>
      </div>
    </div>
  </div>
</div>        
</section>
<nav class="navbar navbar-expand-lg navbar-dark fixed-top">
  <div class="container">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarLinks" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
  
    <div class="collapse navbar-collapse" id="navbarLinks">
      <ul class="navbar-nav">
        <li class="nav-item active">
          <a class="nav-link" href="index.html">INICIO</a>
        </li>
        <li class="nav-item @@about">
          <a class="nav-link" href="#esaky">ESAKY</a>
        </li>
        <li class="nav-item @@service">
          <a class="nav-link" href="#servicios">SERVICIOS</a>
        </li>
        <li class="nav-item @@gallery">
          <a class="nav-link" href="#otros">OTROS SERVICIOS</a>
        </li>
        <li class="nav-item @@team">
          <a class="nav-link" href="#precios">PRECIOS</a>
        </li>
        <li class="nav-item @@appointment">
          <a class="nav-link" href="#autofactura">AUTOFACTURACION</a>
        </li>
        
        <li class="nav-item @@contact">
          <a class="nav-link" href="#contacto">CONTACTO</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
<div class="hero-slider">
  <!-- Slider Item -->
  <div class="slider-item slide1" style="background-image:url(images/slider/slider-bg-1.jpg)">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <!-- Slide Content Start -->
          <div class="content style text-center">
            <h2 class="text-white text-bold mb-2" data-animation-in="slideInLeft">CONTROL TOTAL FÁCIL</h2>
            <p class="tag-text mb-4" data-animation-in="slideInRight">Administra los procesos de tu negocio desde cualquier lugar</p>
            <a href="#servicios" class="btn btn-main btn-white" data-animation-in="slideInLeft" data-duration-in="1.2">VER MÁS</a>
          </div>
          <!-- Slide Content End -->
        </div>
      </div>
    </div>
  </div>
</div>
<section class="story bg-white" id="esaky">
    <div class="service-box tab-pane fade show active" >
        <div class="container">
              <div class="row">
                <div class="col-lg-5">
                  <img loading="lazy" class="img-fluid" src="images/services/one.png" alt="service-image">
                </div>
                <div class="col-lg-7">
                  <div class="contents">
                    <div class="section-title">
                      <br>
                        <h2 >"CONTROL TOTAL FÁCIL"</h2>
                    </div>
                    <div class="text">
                      <p>Es un sistema multiusuario que te permitirá el control y la administración de los procesos de tu negocio desde cualquier lugar, con una conexión a internet, ya que es un sistema 100% online, respaldado por la seguridad y protección de tu información.</p>
                    </div>
                    <ul class="content-list">
						<li>
                            <i class="far fa-dot-circle"></i>Administración de diferentes RFC en una plataforma.
						</li>
                      <li>
                        <i class="far fa-dot-circle"></i>Factura electrónica 3.3.

                      </li>
                      <li>
                        <i class="far fa-dot-circle"></i>Control de entrada y salida de productos.
						</li>
                      <li>
                        <i class="far fa-dot-circle"></i>Control de tus cuentas por pagar.
						</li>
						<li>
                        <i class="far fa-dot-circle"></i>Timbra recibos de nomina en un clic.
						</li>
						<li>
                        <i class="far fa-dot-circle"></i>Contabilidad electrónica.
						</li>
						<li>
                        <i class="far fa-dot-circle"></i>Portal auto facturación.

						</li>
                    </ul>
                    <a href="#" class="btn btn-style-one" data-toggle="modal" data-target="#exampleModal">DEMO ONLINE</a>
                  </div>
                </div>
              </div>
            </div>
	</div>
    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 style="color: #de061d; text-align: center" class="modal-title" id="exampleModalLabel">AVISO</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="color: #000">
       Ingresa los siguientes datos para iniciar la sesión<br>
       USUARIO: prueba<br>
       CONTRASEÑA: prueba      
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal">Cancelar</button>
        <a href="https://sistema.esaky.mx/#/register" target="_blank"><button type="button" class="btn btn-success">Continuar</button></a>
      </div>
    </div>
  </div>
</div>
</section>
<section class="service-tab-section section bg-light" id="servicios">
  <div class="outer-box clearfix">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <!-- Nav tabs -->
          <div class="tabs mb-5">
            <ul class="nav nav-tabs justify-content-center" id="aboutTab" role="tablist">
              <li class="nav-item" role="presentation">
                <a class="nav-link active" id="dormitory-tab" data-toggle="tab" href="#ventas" role="tab" aria-controls="dormitory" aria-selected="true">VENTAS</a>
              </li>
              <li class="nav-item" role="presentation">
                <a class="nav-link" id="orthopedic-tab" data-toggle="tab" href="#compras" role="tab" aria-controls="orthopedic" aria-selected="false">COMPRAS</a>
              </li>
              <li class="nav-item" role="presentation">
                <a class="nav-link" id="sonogram-tab" data-toggle="tab" href="#inventarios" role="tab" aria-controls="sonogram" aria-selected="false">INVENTARIOS</a>
              </li>
              <li class="nav-item" role="presentation">
                <a class="nav-link" id="x-ray-tab" data-toggle="tab" href="#rh" role="tab" aria-controls="x-ray" aria-selected="false">RECURSOS HUMANOS</a>
              </li>
              <li class="nav-item" role="presentation">
                <a class="nav-link" id="diagnostic-tab" data-toggle="tab" href="#contabilidad" role="tab" aria-controls="diagnostic" aria-selected="false">CONTABILIDAD ELECTRONICA</a>
              </li>
            </ul>
          </div>
          <!--Start single tab content-->
          <div class="tab-content" id="aboutTab">
            <div class="service-box tab-pane fade show active" id="ventas">
              <div class="row">
                <div class="col-lg-6">
                  <img loading="lazy" class="img-fluid" src="images/services/service-one.jpg" alt="service-image">
                </div>
                <div class="col-lg-6">
                  <div class="contents">
                    <div class="section-title">
                      <h3>VENTAS</h3><br>
                    </div>
                    
						<ul class="content-list" style="text-align: justify">
                            <li><i class="far fa-dot-circle"></i>Te permite administrar tus ingresos mediante el registro de las ventas con un PUNTO DE VENTA integrado.</li>
                      <li><i class="far fa-dot-circle"></i>El catálogo de clientes te permite identificar, saldos, créditos y llevar el control sobre las cuentas por cobrar.</li>
                            <li><i class="far fa-dot-circle"></i>Crea cotizaciones/remisiones y conviértelas a facturas con un clic y transfórmalas en compras con un clic.</li>	
                            <li><i class="far fa-dot-circle"></i>Emite facturas y envíalas a tu cliente de forma automática.</li>
                            <li><i class="far fa-dot-circle"></i>Tienes la seguridad de estar emitiendo comprobantes 100% válidos y de cumplir con las especificaciones del SAT.</li>
                            <li><i class="far fa-dot-circle"></i>Obtén tus XML de INGRESOS con nuestra herramienta de descarga directa del SAT.</li>	  

						</ul>	
                    
                    <a href="#" class="btn btn-style-one" data-toggle="modal" data-target="#exampleModal">DEMO ONLINE</a>
                  </div>
                </div>
              </div>
            </div>
            <!--End single tab content-->
            <!--Start single tab content-->
            <div class="service-box tab-pane fade" id="compras">
              <div class="row">
                <div class="col-lg-6">
                  <img loading="lazy" class="img-fluid" src="images/services/service-two.jpg" alt="service-image">
                </div>
                <div class="col-lg-6">
                  <div class="contents">
                    <div class="section-title">
                      <h3>COMPRAS</h3><br>
                    </div>
                      <ul class="content-list" style="text-align: justify">
                          <li><i class="far fa-dot-circle"></i>Registra tus proveedores y lleva el control de tus cuentas por pagar.</li>
                          <li><i class="far fa-dot-circle"></i>Crea órdenes de compra para tus proveedores y envíalas por correo.</li>
                          <li><i class="far fa-dot-circle"></i>Carga los XML de tus compras y alimenta de forma automática tu Inventario.</li>	
                          <li><i class="far fa-dot-circle"></i> Obtén tus XML EGRESOS con nuestra herramienta de descarga directa del SAT.</li>
                          <li><i class="far fa-dot-circle"></i>Si tienes contratado el paquete con el módulo contable, el cargar los XMl de proveedores te hará mas sencillo elaborar tu CONTABILIDAD ELECTRÓNICA.</li>
                    </ul>
                   
                    
                   <a href="#" class="btn btn-style-one" data-toggle="modal" data-target="#exampleModal">DEMO ONLINE</a>
                  </div>
                </div>
              </div>
            </div>
            <!--End single tab content-->
            <!--Start single tab content-->
            <div class="service-box tab-pane fade" id="inventarios">
              <div class="row">
                <div class="col-lg-6">
                  <img loading="lazy" class="img-fluid" src="images/services/service-three.jpg" alt="service-image">
                </div>
                <div class="col-lg-6">
                  <div class="contents">
                    <div class="section-title">
                      <h3>INVENTARIOS</h3><br>
                    </div>
                      <ul class="content-list" style="text-align: justify">
                          <li><i class="far fa-dot-circle"></i>Te permitirá controlar la entrada y salida de productos con los indicadores de existencias.</li>
                      <li><i class="far fa-dot-circle"></i>Te permitirá realizar movimientos y traspasos entre almacenes y llevar un registro detallado.</li>
						<li><i class="far fa-dot-circle"></i>Exporta a EXCEL los movimientos y traspasos.</li>  
                          <li><i class="far fa-dot-circle"></i>Puedes vender un producto con diferentes unidades de medida, manteniendo el orden de las existencias configurando las equivalencias.</li>	
                          <li><i class="far fa-dot-circle"></i> Podrás cargar tu lista de productos mediante un archivo de Excel.</li>
                          <li><i class="far fa-dot-circle"></i>Puedes manejar listas de precios por clientes.</li>
    </ul>
                    
                    
                    <a href="#" class="btn btn-style-one" data-toggle="modal" data-target="#exampleModal">DEMO ONLINE</a>
                  </div>
                </div>
              </div>
            </div>
              
            <!--End single tab content-->
            <!--Start single tab content-->
            <div class="service-box tab-pane fade" id="rh">
              <div class="row">
                <div class="col-lg-6">
                  <img loading="lazy" class="img-fluid" src="images/services/service-four.jpg" alt="service-image">
                </div>
                <div class="col-lg-6">
                  <div class="contents">
                    <div class="section-title">
                      <h3>RECURSOS HUMANOS</h3><br>
                    </div>
                      <ul class="content-list" style="text-align: justify">
                          <li><i class="far fa-dot-circle"></i>Lleva el registro de tus empleados.</li>
                          <li><i class="far fa-dot-circle"></i>Registra percepciones y deducciones.</li>
                          <li><i class="far fa-dot-circle"></i>Timbra los recibos de nómina de forma manual.</li>  
                          <li><i class="far fa-dot-circle"></i>También puedes timbrar todos tus recibos del mismo periodo con un clic.</li>
                          <li><i class="far fa-dot-circle"></i>Exporta el catálogo de trabajadores en Excel.</li>
                          <li><i class="far fa-dot-circle"></i>Obtén reportes detallados de los recibos de nomina y expórtalos en Excel.</li>
    </ul> 
                    
                    <a href="#" class="btn btn-style-one" data-toggle="modal" data-target="#exampleModal">DEMO ONLINE</a>
                  </div>
                </div>
              </div>
            </div>
            <!--End single tab content-->
            <!--Start single tab content-->
            <div class="service-box tab-pane fade" id="contabilidad">
              <div class="row">
                <div class="col-lg-6">
                  <img loading="lazy" class="img-fluid" src="images/services/service-five.jpg" alt="service-image">
                </div>
                <div class="col-lg-6">
                  <div class="contents">
                    <div class="section-title">
                      <h2>CONTABILIDAD ELECTRÓNICA</h2><br>
                    </div>
                      <ul class="content-list" style="text-align: justify">
                          <li><i class="far fa-dot-circle"></i>Deja atrás la captura manual y procesos laboriosos de tu contabilidad.</li>
                      <li><i class="far fa-dot-circle"></i>Obtén tus XML de INGRESOS y EGRESOS con nuestra herramienta de descarga directa del SAT.</li>
						<li><i class="far fa-dot-circle"></i>Configura tus cuentas contables con un proceso muy sencillo, no te preocupes por cargar los catálogos del SAT, nosotros ya lo tenemos completo para ti.</li>  
                          <li><i class="far fa-dot-circle"></i>Genera tus pólizas de una manera sencilla y automatizada para evitar errores y ahorrar mucho tiempo.</li>
                          <li><i class="far fa-dot-circle"></i>Todas las actualizaciones del SAT incluidas sin costo adicional.</li>  
    </ul> 
                    
                    
                    <a href="#" class="btn btn-style-one" data-toggle="modal" data-target="#exampleModal">DEMO ONLINE</a>
                  </div>
                </div>
              </div>
            </div>
            <!--End single tab content-->
              
          </div>
        </div>
      </div>
    </div>
  </div>
    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 style="color: #de061d; text-align: center" class="modal-title" id="exampleModalLabel">AVISO</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="color: #000">
       Ingresa los siguientes datos para iniciar la sesión<br>
       USUARIO: prueba<br>
       CONTRASEÑA: prueba      
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal">Cancelar</button>
        <a href="https://sistema.esaky.mx/#/register" target="_blank"><button type="button" class="btn btn-success">Continuar</button></a>
      </div>
    </div>
  </div>
</div>
</section>
<section class="service-section  section" id="otros">
  <div class="container">
    <div class="section-title text-center">
      <h3>OTROS SERVICIOS
      </h3>
      
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="items-container">
          <div class="item">
            <div class="inner-box">
              <div class="img_holder">
                <a href="service.html">
                  <img loading="lazy" src="images/gallery/1.jpg" alt="images" class="img-fluid">
                </a>
              </div>
              <div class="image-content ">
               
                <a href="service.html">
                  <h6>TIENDA EN LÍNEA</h6>
                </a>
                <p style="text-align: justify">Se desarrolla una aplicación para comercio electrónico vía web .Incluye adecuación de la tienda en cuanto a interfaz gráfica que el cliente deseé.</p><br><br>
                  <a href="#contacto" class="btn btn-style-one">Solicitar una Cotización</a>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="inner-box">
              <div class="img_holder">
                <a href="service.html">
                  <img loading="lazy" src="images/gallery/2.jpg" alt="images" class="img-fluid">
                </a>
              </div>
              <div class="image-content s">
                
                <a href="service.html">
                  <h6>HOSTING</h6>
                </a>
                <p>Se habilita un espacio de hosting de página web y base de datos incluyendo: FTP, PHP, CPANEL, MYSQL (5 GB, 10 GB, 15 GB Y 20 GB)</p><br><br>
                  <a href="#contacto" class="btn btn-style-one">Solicitar una Cotización</a>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="inner-box">
              <div class="img_holder">
                <a href="service.html">
                  <img loading="lazy" src="images/gallery/5.jpg" alt="images" class="img-fluid">
                </a>
              </div>
              <div class="image-content ">
                
                <a href="service.html">
                  <h6>DESARROLLO WEB</h6>
                </a>
                <p>Desarrollo de programas a la medida de sus necesidades, reporteadores y páginas Web</p><br><br><br>
                  <a href="#contacto" class="btn btn-style-one">Solicitar una Cotización</a>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="inner-box">
              <div class="img_holder">
                <a href="service.html">
                  <img loading="lazy" src="images/gallery/3.jpg" alt="images" class="img-fluid">
                </a>
              </div>
              <div class="image-content ">
                
                <a href="service.html">
                  <h6>DESARROLLO DE APPS</h6>
                </a>
                <p style="text-align: justify">Se desarrolla una aplicación móvil utilizando el framework de Ionic que permite generar apps para Android y IOS, además de que se construye una API para el manejo del backend.</p><br>
                  <a href="#contacto" class="btn btn-style-one">Solicitar una Cotización</a>
              </div>
            </div>
          </div>
          
          
        </div>
      </div>
    </div>
  </div>
</section>
<section class="appoinment-section section bg-light">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="accordion-section">
  
    <div class="section-title text-center" id="precios"><br>
      <h3 style="text-align: center; color: #e2092a">PREGUNTAS FRECUENTES - COSTOS </h3>
      <p style="font-size: 16px; color: #000">Para solucionar tus dudas en cuanto a pagos te recomendamos leer la siguiente sección</p>
    </div>
 
  <div class="accordion-holder">
    <div class="accordion" id="accordionGroup" role="tablist" aria-multiselectable="true">
      <div class="card">
        <div class="card-header" role="tab" id="headingOne">
          <h4 class="card-title">
            <a role="button" data-toggle="collapse" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
             ¿Cómo puedo pagar y cuánto cuesta?
            </a>
          </h4>
        </div>
        <div id="collapseOne" class="collapse show" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordionGroup">
          <div class="card-body">
         Puedes pagar por depósito, transferencia o tarjetas bancarias. Tenemos planes de desde $199 MXN mensuales.
         </div>
        </div>
      </div>
        <div class="card">
        <div class="card-header" role="tab" id="headingTwo">
          <h4 class="card-title">
            <a class="collapsed" role="button" data-toggle="collapse" href="#collapseTwo"
              aria-expanded="false" aria-controls="collapseTwo">
             ¿Cada cuánto se paga el software?

            </a>
          </h4>
        </div>
        <div id="collapseTwo" class="collapse" role="tabpanel" aria-labelledby="headingTwo" data-parent="#accordionGroup">
          <div class="card-body">
            Tú eliges, si te conviene realizar un pago anual o si prefieres pagos mensuales. El pago anual tiene un descuento en monto total comparado a si pagas 12 meses.

          </div>
        </div>
      </div>
        <div class="card">
        <div class="card-header" role="tab" id="headingThree">
          <h4 class="card-title">
            <a class="collapsed" role="button" data-toggle="collapse" href="#collapseThree"
              aria-expanded="false" aria-controls="collapseThree">
             ¿En caso de vencer mi plan en día inhábil o festivo se desactiva mi cuenta?
            </a>
          </h4>
        </div>
        <div id="collapseThree" class="collapse" role="tabpanel" aria-labelledby="headingTwo" data-parent="#accordionGroup">
          <div class="card-body">
            Nuestro proceso de administración está automatizado por tal motivo si será inhabilitada tu cuenta de forma automática, te recomendamos hacer tu pago con anticipación para evitar inactividad en tu cuenta.


          </div>
        </div>
      </div>
        <div class="card">
        <div class="card-header" role="tab" id="headingThree">
          <h4 class="card-title">
            <a class="collapsed" role="button" data-toggle="collapse" href="#collapseForth"
              aria-expanded="false" aria-controls="collapseForth">
              ¿Existen pagos extras?
            </a>
          </h4>
        </div>
        <div id="collapseForth" class="collapse" role="tabpanel" aria-labelledby="headingTwo" data-parent="#accordionGroup">
          <div class="card-body">
           No, ESAKY no maneja ningún tipo de pagos extras, a tu cuenta. Solo si requieres más usuarios, módulos o timbrar facturas, y es opcional.



          </div>
        </div>
      </div>	
    </div>
  </div>
</div>
      </div>
      
    </div>                    
  </div>
	
</section>
<section id="pricing" class="pricing bg-light"><br>
	<div class="section-title text-center" ><br>
      <h2 style="text-align: center; color: #e2092a; font-size: 40px">PLANES ESAKY </h2>
      <p style="font-size: 16px; color: #000">Seleccciona el Plan que mas se acerque a tus necesidades</p>
    </div>
      <div class="container"> 
        <div class="row">

          <div class="col-lg-3 col-md-6">
            <div class="box">
              <h3>BASICO</h3>
              <h4><sup>$</sup>199.00<span> / mes</span></h4>
              <ul>
                <li>VENTAS</li>
                <li class="na">COMPRAS</li>
                <li class="na">INVENTARIOS</li>
                <li class="na">RECURSOS HUMANOS</li>
                <li class="na">CONTABILIDAD ELECTRONICA</li>
              </ul>
              <div class="btn-wrap">
                <a href="#" target="_blank" class="btn-buy"  data-toggle="modal" data-target="#exampleModal2">Comprar</a>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-4 mt-md-0">
            <div class="box featured">
              <h3>PYME 1</h3>
              <h4><sup>$</sup>449.00<span> / mes</span></h4>
              <ul>
                <li>VENTAS</li>
                <li>COMPRAS</li>
                <li>INVENTARIOS</li>
                <li class="na">RECURSOS HUMANOS</li>
                <li class="na">CONTABILIDAD ELECTRONICA</li>
              </ul>
              <div class="btn-wrap">
                <a href="#" target="_blank" class="btn-buy"  data-toggle="modal" data-target="#exampleModal2">Comprar</a>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-4 mt-lg-0">
            <div class="box">
              <h3>PYME 2</h3>
              <h4><sup>$</sup>549.00<span> / mes</span></h4>
              <ul>
                <li>VENTAS</li>
                <li>COMPRAS</li>
                <li>INVENTARIOS</li>
                <li>RECURSOS HUMANOS</li>
                <li class="na">CONTABILIDAD ELECTRONICA</li>
              </ul>
              <div class="btn-wrap">
                <a href="#" target="_blank" class="btn-buy"  data-toggle="modal" data-target="#exampleModal2">Comprar</a>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-4 mt-lg-0">
            <div class="box">
              <span class="advanced">control total</span>
              <h3>PYME 3</h3>
              <h4><sup>$</sup>799.00<span> / mes</span></h4>
              <ul>
                <li>VENTAS</li>
                <li>COMPRAS</li>
                <li>INVENTARIOS</li>
                <li>RECURSOS HUMANOS</li>
                <li>CONTABILIDAD ELECTRONICA</li>
              </ul>
              <div class="btn-wrap">
                <a href="#" target="_blank" class="btn-buy"  data-toggle="modal" data-target="#exampleModal2">Comprar</a>
              </div>
            </div>
          </div>

        </div>
          <div class="modal fade" id="exampleModal2" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 style="color: #de061d; text-align: center" class="modal-title" id="exampleModalLabel">AVISO</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="color: #000">
        Al registrarte podrás tener acceso inmediato al sistema funcional, y podrás empezar inclusive a facturar. <br>Empieza a usarlo, pronto un compañero de ESAKY te contactará para concretar el alta, configuración básica y esquema de contratación. <br>Gracias por tu confianza y sé bienvenido de parte de todo el equipo ESAKY, el mejor aliado del orden y control de tu negocio.
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal">Cancelar</button>
        <a href="https://sistema.esaky.mx/#/register" target="_blank"><button type="button" class="btn btn-success">Comprar</button></a>
      </div>
    </div>
  </div>
</div>
      </div><br><br>
    </section>
<section class="testimonial-section" id="autofactura" >
	<div class="section-title text-center"><br>
      <h3>Portal Autofacturacion
      </h3>
      
    </div>
 
  <div class="service-box tab-pane fade show active" id="dormitory">
      <div class="container">
              <div class="row">
                <div class="col-lg-5">
                  <img loading="lazy" class="img-fluid" src="images/services/two.jpg" alt="service-image">
                </div>
                <div class="col-lg-7">
                  <div class="contents">
                    <div class="section-title">
                      <br>
                        <h2 >PORTAL AUTOFACTURACIÓN </h2>
                    </div>
                    <div class="text">
                     
                    </div>
                    <ul class="content-list">
                      <li>
                        <i class="far fa-dot-circle"></i>Integra a KEASYERP el portal de auto facturación y permite a tus clientes generar sus propias facturas y descargarlas las veces que lo requieran.
                      </li>
                      <li>
                        <i class="far fa-dot-circle"></i>Validación del ticket: verifica que el Folio/Referencia estén registrados en la plataforma.
						</li>
                      <li>
                        <i class="far fa-dot-circle"></i>Validación del Importe: verifica que el importe coincida con el ticket registrado en la plataforma.
						</li>
						<li>
                        <i class="far fa-dot-circle"></i>Validación del RFC: verifica que el RFC del cliente este registrado de acuerdo a la lista de RFC’s inscritos por el SAT.
						</li>
						<li>
                        <i class="far fa-dot-circle"></i>Sitio personalizado con la imagen de la empresa.
						</li>
						<li>
                        <i class="far fa-dot-circle"></i>Instalación en dominio propio o en nuestros servidores.
						</li>
						<li>
                        <i class="far fa-dot-circle"></i>Panel de soporte técnico integrado.
						</li>
                    </ul>
                    <a href="https://demoautofactura.kreativisys.com.mx/index.html" target="_blank" class="btn btn-style-one">DEMO ONLINE</a>
                  </div>
                </div>
              </div>
            </div>
	</div>
</section>
<section class="appoinment-section section bg-light">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="accordion-section">
  <div class="section-title">
    <h3 style="text-align: center; color: #e2092a">PREGUNTAS GENERALES</h3>
  </div>
  <div class="accordion-holder">
    <div class="accordion" id="accordionGroup" role="tablist" aria-multiselectable="true">
      <div class="card">
        <div class="card-header" role="tab" id="headingOne">
          <h4 class="card-title">
            <a role="button" data-toggle="collapse" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
             ¿Diferencias con otras marcas?

            </a>
          </h4>
        </div>
        <div id="collapseOne" class="collapse show" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordionGroup">
          <div class="card-body">
          ESAKY utiliza un sistema electrónico que realiza los registros sincronizados para todas las áreas de tu empresa en la nube, con un alto nivel de automatización para tu mayor ahorro de tiempo, y en mejora continua, pudiendo tener todos los módulos o solo los que tu negocio necesite (ventas, gastos, inventario, recursos humanos y contabilidad).


          </div>
        </div>
      </div>
		
      <div class="card">
        <div class="card-header" role="tab" id="headingTwo">
          <h4 class="card-title">
            <a class="collapsed" role="button" data-toggle="collapse" href="#collapseTwo"
              aria-expanded="false" aria-controls="collapseTwo">
             ¿Tipo de contribuyentes que contabiliza?

            </a>
          </h4>
        </div>
        <div id="collapseTwo" class="collapse" role="tabpanel" aria-labelledby="headingTwo" data-parent="#accordionGroup">
          <div class="card-body">
            El sistema electrónico ESAKY trabaja cualquier tipo de contribuyente y actividad preponderante para personas físicas o morales.
          </div>
        </div>
      </div>
		<div class="card">
        <div class="card-header" role="tab" id="headingThree">
          <h4 class="card-title">
            <a class="collapsed" role="button" data-toggle="collapse" href="#collapseThree"
              aria-expanded="false" aria-controls="collapseThree">
             ¿Qué herramientas y servicios son los que incluye?

            </a>
          </h4>
        </div>
        <div id="collapseThree" class="collapse" role="tabpanel" aria-labelledby="headingThree" data-parent="#accordionGroup">
          <div class="card-body">
           Almacenamiento por 5 años, movimientos, dispositivos conectados, actualizaciones,  reproducciones de descarga masiva, y más.


          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" role="tab" id="headingfor">
          <h4 class="card-title">
            <a class="collapsed" role="button" data-toggle="collapse" href="#collapsefor"
              aria-expanded="false" aria-controls="collapsefor">
             ¿Cuántos RFC puede llevar?
            </a>
          </h4>
        </div>
        <div id="collapsefor" class="collapse" role="tabpanel" aria-labelledby="headingfor" data-parent="#accordionGroup">
          <div class="card-body">
            Existen diferentes planes según el número de registros o RFC que requieras. Todos los planes incluyen herramientas, servicios y soporte técnico remoto sin importar el tipo de pago o promoción contratada.

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
      </div>
      
    </div>                    
  </div>
	
</section>
<section class="section contact bg-white" id="contacto">
    <div class="section-title">
    <h3 style="text-align: center; color: #e2092a">CONTÁCTANOS</h3><br>
  </div>
  <!-- container start -->
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-5 ">
        <!-- address start -->
        <div class="address-block">
          <!-- Location -->
          <div class="media">
            <i class="far fa-map"></i>
            <div class="media-body">
              <h3>Dirección</h3>
              <p style="font-size: 16px">Merced Gómez No. 28<br>Benito Juárez C.P. 03930<br> Ciudad de México</p>
            </div>
          </div>
          <!-- Phone -->
          <div class="media">
            <i class="fas fa-phone"></i>
            <div class="media-body">
              <h3>Teléfono</h3>
              <p>
                (722) 270-7021
                <br>(722) 270-7022
              </p>
            </div>
          </div>
          <!-- Email -->
          <div class="media">
            <i class="far fa-envelope"></i>
            <div class="media-body">
              <h3>E-mail</h3>
              <p>contacto@esaky.mx</p>
            </div>
          </div>
        </div>
        <!-- address end -->
      </div>
      <div class="col-lg-8 col-md-7">
        <div class="contact-form">
          <!-- contact form start -->
          <form action="!#" class="row">
            <!-- name -->
            <div class="col-lg-6">
              <input type="text" name="name" class="form-control main" placeholder="Name" required>
            </div>
            <!-- email -->
            <div class="col-lg-6">
              <input type="email" class="form-control main" placeholder="Email" required>
            </div>
            <!-- phone -->
            <div class="col-lg-12">
              <input type="text" class="form-control main" placeholder="Phone" required>
            </div>
            <!-- message -->
            <div class="col-lg-12">
              <textarea name="message" rows="10" class="form-control main" placeholder="Your message"></textarea>
            </div>
            <!-- submit button -->
            <div class="col-md-12 text-right">
              
            </div>
          </form>
          <!-- contact form end -->
        </div>
      </div>
    </div>
  </div>
    
  <!-- container end -->
</section>
 <div id="back-to-top" class="back-to-top">
  <i class="fa fa-angle-up"></i>
</div>
 <div class="scroll-to-top scroll-to-target" data-target=".header-top">
  <span class="icon fa fa-angle-up"></span>
</div>
 <a href="https://api.whatsapp.com/send?phone=527222470972&text=Sistema%20de%20Control%20Empresarial%20Facturacion%20Electronica%20Control%20de%20Inventarios%20Contabilidad%20Electronica" target="_blank" class="float">
<img src="images/whats.png" class="my-float">
</a>

<a href="https://www.facebook.com/ESAKY-763006713863687" target="_blank" class="float2">
<img src="images/face.png" class="my-float2">
</a>
<a href="https://sistema.esaky.mx/#/" target="_blank" class="float2">
<img src="images/ins.png" class="my-float3">
</a>            
<section class="map">
  <!-- Google Map -->
  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3764.0552171324057!2d-99.19226488561888!3d19.36676164780236!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d20027595cbd99%3A0x3dd6f1bd45accfc0!2sMerced%20G%C3%B3mez%2028%2C%20Merced%20G%C3%B3mez%2C%20Benito%20Ju%C3%A1rez%2C%2003930%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX!5e0!3m2!1ses-419!2smx!4v1613149818730!5m2!1ses-419!2smx" width="1400" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
</section>
<footer class="footer-main">
  
  <div class="footer-bottom">
    <div class="container clearfix">
      <div class="copyright-text">
        <p>&copy; Copyright 2021. ESAKY</p>
      </div>
      
    </div>
  </div>
</footer>
</div>
	</div>
  </div>
</template>

<script>
// @ is an alias to /src


export default {
  name: 'Home',
 mounted() {
        const script = document.createElement('script')
        script.src = 'js/script.js'
        document.body.appendChild(script)
    }
}
</script>
